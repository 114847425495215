<template>
    <div>
        <Quote/>

        <div class="course-title-box">
            <div class="row">
                <p class="course-title pt-5 col-10">Junior Full Stack Web Developer</p>
            </div>
            <div class="row justify-content-around">
                <div class="">
                    <p class="course-subtitle mt-4 pb-5 col-10">
                        Kursda 0 dan boshlab professional dasturchi
                        bo’lish <br/>uchun barcha kerakli bilimlar beriladi.
                    </p>
                </div>
            </div>
        </div>

        <div class="course-reg-button d-flex justify-content-end fixed-bottom mb-5 me-5">
            <a
                id="goToPurchase"
                class="btn btn-secondary p-2 col-6 col-sm-4 col-md-3 col-lg-2"
                href="#payment"
                @click="toDown"
            >
                Kursga yoziling
            </a>
        </div>

        <div class="technologies pt-5">
            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="image12"/>
                </div>

                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Umumiy dasturlash teoriyasi</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        O’quv markazlari odatda ma’lum bir dasturlash tilini o’rgatishadi holos. Men esa
                        kursning 3 haftasini dasturlash asosini o’rgatishga sarflayman. Bu bilim bilan siz xohlagan
                        dasturlash tilida be’malol dasturlar yoza oladigan bo’lasiz.</p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="PHP"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">PHP</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Hozirgi internetlashgan zamonda bu til eng muhim daturlash tillaridan biri hisoblanadi.
                        Ma’lumot uchun: internetdagi web dasturlar va saytlarning 80% shu tilda yozilgan. Shu jumladan
                        Facebook ham aynan shu tilda yozilgan.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="JS"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">JavaScript</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Ushbu til brauzerlar uchun ishlab chiqilgan bo’lib, hozirgi kunda deyarli barcha
                        dasturlash yo’nalishlarida keng qo’llaniladi. Brauzerlarda yagona dasturlash tili hisoblanadi.
                        Hozir mobil dasturlarni ham ushbu tilda yozish urf bo’lmoqda.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="MySQL"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">MySQL</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Har bir dastur ma’lumotlarni qayerdadir saqlashi kerak. Misol uchun foydalanuvchining
                        elektron manzili, ism-sharfi va hokazo. Buning uchun MySQL ma’lumotlar ombori tizimi bor.
                        Ma’lumot uchun: Facebook barcha ma’lumotlarni aynan MySQL tizimida
                        saqlaydi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="HTML-CSS"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">HTML va CSS</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Bu tillar dasturlash tillariga kirmaydi, ular veb-dasturlarning tashqi ko’rinishini
                        ifodalash uchun ishlatiladi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Bootstrap"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Bootstrap</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Ushbu freymvork – dasturning tashqi ko’rinishi uchun kuchli qurol hisoblanadi. HTML,
                        CSS va JavaScript larning tayyor qoliplarini jamlagan. Siz tashqi ko’rinish uchun ko’p kod
                        yozmasdan, tayyor chiroyli bootstrap elementlaridan foydalanishingiz mumkin.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Symfony"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Symfony</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Backend uchun eng kuchli freymvork. Dunyoda 3 ta mashhur freymvorklar bor. Symfony, Yii va
                        Laravel. Laravel
                        ko’proq Kanada davlatida ishlatiladi. Symfony esa Yevropa davlatlarida. Yii freymvorki Rossiya
                        va
                        O’zbekistonda. Lekin Yii shunchalik eskirdiki ushbu davlatlar ham aynan Symfony freymvorkiga
                        o’tishni
                        boshlashdi. Backend dastur yaratish uchun ushbu freymvorkka teng keladigani yo’q.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Api-Platform"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Api-Platform</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        API yaratish uchun juda qulay instrument hisoblanadi. Front-end’dan keladigan
                        so’rovlarni boshqarishni avtomatlashtiradi, o’zi Swagger ushun maxsus kodlarni generatsiya
                        qiladi. Oddiy so’z bilan aytganda, boshqa dasturchilar 2 hafta ichida yozishi mumkin bo’lgan
                        dasturni, API-Platform orqali 30 daqiqada yozish mumkin.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="VueJS"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">VueJS</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Frontend uchun eng zamonaviy freymvork. Ushbu freymvork orqali nafaqat veb uchun,
                        balki mobil qurilmalar uchun, kompyuter uchun ham frontend dasturlar yozishingiz mumkin.
                        Undan tashqari, Vuex ni ham o’rganamiz.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Git"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Git</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Loyihangiz versiyalarini boshqaruvchi git dasturisiz, hozirgi zamonaviy
                        dasturlarni tasavvur qilish qiyin. Git har bir dasturdagi o’zgargan kodlarni, fayllarni
                        boshqarib boradi. Git orqali bir necha yil oldin o’chirib yuborilgan fayylarni ham tiklash, yoki
                        har bir o’zgarishlarni ko’rish imkoningiz bo’ladi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Linux"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Linux</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Linux terminalida ishlashni o’rganasiz. Afsuski Windows foydalanuvchilari ushbu asosiy
                        buyruqlarni bilishmaydi. Hozirgi kunda qariyib barcha ish beruvchilar, dasturchilarni asosiy
                        linux buyruqlarini bilishi kerak deb hisoblashadi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Design-Patterns"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Design Patterns</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">Bundan tashqari professional dasturchilar har xil
                        loyihalashtirish qoliplari (рус: Шаблоны проектирования, eng: Design Patterns) ni ishlatishadi,
                        ushbu kurs davomida eng kerakli va ko’p ishlatiladigan qoliplarni o’rganamiz.
                    </p>
                </div>
            </div>

        </div>

        <div class="course-title-box mb-5">
            <p class="course-title pt-5 col-10 fadeIn">
                Hamma dasturlashni o’rganishi shart.<br> Chunki u sizga o’ylashni o’rgatadi.
            </p>
            <p class="course-subtitle mt-4 pb-5 col-10 fadeIn">© Steve Jobs</p>
        </div>

        <div class="information col-11 col-lg-9 pb-5 mt-4">
            <div>
                <h5 class="course-title ms-5 course-information">
                    <b>Kursda qatnashish uchun nimalar kerak bo’ladi?</b>
                </h5>
                <ul class="divp6">
                    <li><b>Kompyuter, yoki noutbuk</b></li>
                    <li>
                        Darslar<b> Zoom, Discord </b> va <b>Telemost</b> dasturlarining biri orqali onlayn tarzda
                        o'tiladi. Siz o’qituvchi va uning elektron doskasini ko’rib turasiz. Ushbu dasturlarni bu yerdan
                        yuklab olishingiz mumkin: <br />
                        <a class="hover-decoration" href="https://zoom.us/download" target="_blank">Zoom</a>,
                        <a class="hover-decoration" href="https://discord.com/download" target="_blank">Discord</a>,
                        <a
                            class="hover-decoration"
                            href="https://play.google.com/store/apps/details?id=ru.yandex.telemost&hl=ru&gl=US"
                            target="_blank"
                        >
                            Telemost
                        </a> <br />
                    </li>
                    <li>
                        <b>Internet tezligi</b> video a’loqa qilish uchun yetarli bo’lishi kerak. Tekshirish uchun
                        fast.com saytiga kiring. Tezligingiz yetarli darajada bo’lishi uchun sayt kamida 2 mega bit
                        tezlik ko’rsatishi kerak.
                    </li>
                    <li>O’qituvchi ba boshqa o’quvchilar bilan suhbat qilish uchun <b>mikrofoni bor naushnik</b> kerak
                        bo’ladi. Telefon naushniklari ham to’g’ri keladi.
                    </li>
                </ul>
            </div>

            <div>
                <h5 class="course-title ms-5 course-information mt-4"><b>Kurs vaqti</b></h5>
                <ul class="divp6">

                    <li><b>Haftasiga 2 kun</b></li>

                    <li>
                        <b>Davomiyligi 2 soat</b> (Dars davomiyiligi, rejaga qarab, ikki soatdan oshishi yoki kamayishi
                        mumkin). Toshkent vaqti bilan soat 20:00 dan 22:00 gacha. Yaxshi dasturchi
                        bo’lish uchun dars bo’lmagan kunlar ham kamida 2 soatdan vaqt ajratish maslahat beriladi.
                    </li>

                    <li>
                        <b>Dars videoga yozib boriladi.</b> Agar biror sababga ko’ra darsda qatnasha olmasangiz, video
                        sizga yuboriladi. Lekin, ko’p darsga qatnasha olmasangiz, siz bilan xayrlashishga to’g’ri
                        kelishi mumkin. Chunki faqat video orqali o’rgansangiz – bu kurs siz uchun oddiy video-darslik
                        bo’lib qoladi. Biz esa bunga qarshimiz.
                    </li>
                </ul>
            </div>

            <div>
                <h5 class="course-title ms-5 course-information mt-4"><b>Kurs haqida</b></h5>
                <ul class="divp6">

                    <li><b>Kurs davomiyligi:</b> 5 oy</li>

                    <li>
                        <b>To’lov turlari:</b> O'zbekistonlik o'quvchilar uzcard yoki Humo kartalari orqali saytimizdan
                        to'lov qilishlari mumkin. Buning uchun
                        <a
                            class="hover-decoration"
                            href="#payment"
                            @click="toDown"
                        >
                            bu yerga bosing.
                        </a>
                        Chet eldagi vatandoshlar mastercard yoki visa kartalariga pul o’tkazish orqali to’lovni amalga
                        oshirishlari mumkin bo'ladi.
                    </li>
                    <li><b>Kurs tili:</b> o’zbekcha</li>
                    <li v-if="this.course.teacher.givenName === null">
                        <b>O’qituvchi:</b> <span class="teachers">Akmal Kadirovning</span>
                        metodikasi bo’yicha u kishining shogirdi dars o’tadi.
                    </li>
                    <li v-else>
                        <b>O’qituvchi:</b> {{ this.course.teacher.givenName + ' ' + this.course.teacher.familyName }}
                    </li>

                    <li><b>Kursga yozilish uchun:</b> 100% oldindan, yoki har oy bo'lib to'lashingiz mumkin.</li>
                </ul>
            </div>
            <div>
                <h5 class="course-title ms-5 course-information mt-4"><b>Kursdan maqsad</b></h5>
                <ul class="divp6">
                    <li>
                        5 oy mobaynida sizga 10 dan ortiq texnologiyalarni o’rgatish. Sizni mustaqil ishlay oladigan
                        professional dasturchiga aylantirish.
                    </li>
                </ul>
            </div>
        </div>

        <div class="Tech pb-5" style="height: auto">
            <div class="row mb-2 col-11 px-3 px-sm-2 px-lg-5">
                <div class="fade-in pl-2">
                    <h1 class="junior-theme">Kursdan so’ng siz Junior Full Stack Developer bo’lasiz.</h1>
                    <p class="mt-2 mb-4 junior-text">Ya’ni, ham Front-end, ham Back-end’da dasturlar yoza olasiz. O’z
                        rezyumengizda esa keyingi
                        bilimlarni qo’shishingiz mumkin bo’ladi:
                    </p>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="U.D.T"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="PHP"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="JavaScript"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="HTML"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="CSS"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Bootstrap"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="MySQL"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Symfony"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Api-Platform"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Vue.js"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Git"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Linux CLI"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Design Patterns"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="phpStorm"/>
                    <div class="col-0 col-sm-6 col-md-4 col-lg-3 col-xl-8 mx-lg-2 mx-xl-0"/>
                </div>
            </div>
        </div>

        <div>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Bu kurs uchun o'quvchilar soni to'lgan</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-promo" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Bunday promo kod mavjud emas yoki amal qilish muddati tugagan</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="can-not-bought" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Siz web-full-stack-junior kursining, bundan yuqoridagi kursini sotib olmagansiz.</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="you-payed" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Siz allaqachon bu kurs uchun to'lov qilgansiz </h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div
            id="payment"
            :class="hasActiveCourses ? 'border-module' : ''"
            class="col-11 col-sm-10 col-md-8 mx-auto mt-5 mb-2 p-3 form-register"
        >
            <h6 v-if="hasActiveCourses" class="text-center">
                Kursga yozilish
            </h6>

            <div v-if="getModules.length" class="my-3">
                <strong>Kursni tanlang</strong>
                <b-form-select
                    id="exampleInputId1"
                    v-model="course.moduleId"
                    class="form-control"
                    name="hero"
                >
                    <option
                        v-for="course in getModules"
                        :key="course.id"
                        :value="course.id"
                    >
                        {{ course.name }}
                    </option>
                </b-form-select>
            </div>
            <label
                v-for="course of this.getModule.courses"
                :key="course.id"
                :for="course.id"
                class="d-block"
            >
                <div
                    v-if="hasActiveCourses"
                    :class="!course.isActive || !course.countOfStudents > 0 ? 'text-danger' : ''"
                    class="my-4 d-block d-md-flex bg-color"
                >

                    <div class="d-flex col-11 col-md-6 pt-2">
                        <div>
                            <input
                                :id="course.id"
                                v-model="boughtCourse.courses"
                                :disabled="isDisabledCheck(course) || !course.isActive || !course.countOfStudents > 0"
                                :value="course.id"
                                class="mr-2 course-check"
                                type="checkbox"
                                @change="checkAction($event)"
                            />
                        </div>

                        <div id="payment" class="form-register">
                            <strong>
                                <p class="course-check">{{ course.name }}</p>
                            </strong>
                        </div>
                    </div>

                    <div class="d-block col-11 col-md-6 pt-1 pt-md-1 m-0">
                        <div class="border-cover">
                            <strong>Boshlanish vaqti:</strong>
                            {{ course.startMonth.name }}
                        </div>
                        <div class="border-cover">
                            <strong>Narxi:</strong>
                            {{ numberWithCommas(course.price / 100) }} so'm
                        </div>

                        <div
                            v-if="course.countOfStudents !== course.members && (course.countOfStudents - course.members) > 0"
                            class="border-cover mb-3 mb-md-0">
                            <strong>
                                <strong class="text-primary">{{ course.countOfStudents }}</strong> ta joydan
                                <strong class="text-danger">
                                    {{ showFreePlace(course.countOfStudents, course.members) }}
                                </strong>
                                ta qoldi
                            </strong>
                        </div>

                        <div v-else class="border-cover mb-3 mb-md-0">
                            <strong>
                                Ushbu Kursda joy qolmadi.
                            </strong>
                        </div>
                    </div>
                </div>
            </label>
            <div v-if="hasActiveCourses && showPromoCodeInput" class="d-flex mb-2">
                <div class="col-8 col-md-9 pt-1">
                    <input
                        v-if="isAuthenticated"
                        v-model="promo"
                        :disabled="myBoughtCourses <= getUserCourses.length"
                        class="form-control mr-2"
                        placeholder="Promokod"
                    />
                </div>
                <div class="col-4 col-md-3 pt-1">
                    <button-component
                        v-if="isAuthenticated"
                        :disabled="myBoughtCourses <= getUserCourses.length"
                        class="ml-2"
                        style="background-color: #027610 !important;"
                        @click="findPromo"
                    >
                        Tekshirish
                    </button-component>
                </div>
            </div>

            <div v-else-if="isAuthenticated && hasActiveCourses" @click="showPromoInput">
                <a class="hover-decoration promo-text">Menda promo kod mavjud</a>
            </div>

            <div v-if="getFoundPromo !== null && !hasDiscount || promo" class="col-12 col-sm-12 col-md-10 mt-5">
                <p>
                    Ushbu promo kod orqali siz
                    <span
                        v-for="(item, index) in getFoundPromo.promos"
                        :key="index"
                        class="course-color"
                    >
                        {{ item.name }}
                    </span>
                    modullari uchun
                    <span class="discount">{{ numberWithCommas(getFoundPromo.discount / 100) }}</span>
                    <span v-if="getFoundPromo.isPercent"> % </span>
                    <span v-else>
                        so'mdan, jami
                        <span class="discount">
                            {{ numberWithCommas(totalSum()) }} so'm
                        </span>
                    </span>
                    chegirmaga ega bo'lasiz!
                </p>
            </div>
            <div v-if="hasActiveCourses" class="d-sm-flex">
                <div v-if="!promo.length" class="col-12 col-sm-8 col-md-9">
                    <p v-if="totalPrice" class="mt-2 pt-2 fs-5 pr-5 text-bold">
                        Jami: {{ numberWithCommas(totalPrice / 100) }} so'm
                    </p>
                    <p v-if="hasDiscount" class="pt-2 fs-5 pr-5 text-bold text-success">
                        <span>
                            Kursning barcha oylarini tanlaganingiz uchun chegirma
                            <span
                                v-if="getModule.discountPercent > 0"
                                style="font-size: x-large;
                                text-decoration: underline"
                            >
                                {{ getModule.discountPercent }}%
                            </span>
                            <span
                                v-else
                                style="font-size: x-large;
                                text-decoration: underline"
                            >
                                10%
                            </span>
                        </span>
                    </p>
                    <p v-if="hasDiscount" class="mt-2 pt-2 fs-5 pr-5 text-bold">
                        Jami:
                        <span v-if="getModule.discountPercent > 0">
                            {{numberWithCommas((totalPrice - (totalPrice * (getModule.discountPercent / 100))) / 100)}}
                        </span>
                        <span v-else>
                            {{ numberWithCommas((totalPrice - (totalPrice * 0.1)) / 100) }}
                        </span>
                        so'm
                    </p>
                </div>
                <div v-else-if="totalPrice > 0" class="col-12 col-sm-8 col-md-9">
                    <p class="mt-2 pt-2 fs-5 pr-5 text-bold">
                        Jami: {{ numberWithCommas(totalPrice / 100) }} so'm
                    </p>
                </div>
                <div class="col-4 col-md-3 ml-auto mt-auto pt-2">
                    <button-component
                        v-if="isAuthenticated"
                        :disabled="myBoughtCourses <= getUserCourses.length"
                        class="ml-2"
                        @click="bought"
                    >
                        To'lash
                    </button-component>
                </div>
            </div>

            <div v-else-if="loading" class="text-center mt-5">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div v-else class="text-center mt-5">
                <h4>Hozirda aktiv web-full-stack-junior kurslarimiz mavjud emas</h4>
            </div>

            <!-- Start Payme Form -->
            <form :action="paymeUrl" class="d-none" method="POST">

                <!-- Payme Cashbox ID  -->
                <input :value="paymeLogin" name="merchant" type="hidden"/>

                <!-- Cost with tiyin -->
                <input :value="payme.amount" name="amount" type="hidden"/>

                <!-- Payment data -->
                <input :value="payme.transactionId" name="account[transactionId]" type="hidden"/>

                <!-- === OPTIONAL DATA === -->
                <!-- Language. By default 'ru'. Available options: ru|uz|en -->
                <input name="lang" type="hidden" value="uz"/>

                <!--
                    Currency. By default '860'. Available options: 643|840|860|978
                    643 - RUB
                    840 - USD
                    860 - UZS
                    978 - EUR
                -->
                <input name="currency" type="hidden" value="860"/>

                <!--
                    URL to redirecting after payment. By default, payme redirects to URL of Referer header value.
                    URL may contain that will be replaced by Payme:
                    :transaction - id of transaction. Can be null if payme couldn't create transaction
                    :account.{field} - field of account object
                    For example: https://your-service.com/payme/:transaction
                -->
                <!--
                    <input type="hidden" name="callback" value="{{ REDIRECT_URL }}"/>
                -->

                <!-- Redirect timeout after successful payment in milliseconds  -->
                <input name="callback_timeout" type="hidden" value="15"/>

                <!--
                    Payment description. You can also specify descriptions in few
                    languages by using description object like name="description[{lang}]".
                    As {lang} you can use ru, en or uz
                -->
                <input :value="payme.description" name="description" type="hidden"/>

                <!--
                    Details of payment. You can use JSON object encoded by BASE64.
                    For example:
                    {
                        "discount": {
                             "title": "discount 5%",
                             "price": 10000
                        },
                        "shipping": {
                              "title": "Shipment to Termez 28/23",
                              "price": 500000
                        },
                        "items": [
                            {
                                "title": "Tomato",
                                "price": 505000,
                                "count": 2
                            }
                        ]
                    }
                 -->
                <input :value="payme.detail" name="detail" type="hidden"/>

                <button ref="paymeButton" type="submit">Pay with <b>Payme</b></button>
            </form>

        </div>

        <div v-if="!isAuthenticated" id="payment" class="sent course pb-2 form-register">
            <div class="d-flex">
                <h5>Kursga yozilish uchun avval
                    <router-link class="login" to="/login">
                        tizimga kiring
                    </router-link>
                    <span class="login"> yoki </span>
                    <router-link class="login" to="/registration">
                        ro'yxatdan o'ting
                    </router-link>
                </h5>
            </div>
        </div>

        <div v-else class="advice pt-0 mt-5">
            <div class="divp7">
                <p>
                    <strong><em>Maslahat: </em></strong>
                    To'lovni amalga oshirganingiz, bu yaxshi dasturchi bo'ldingiz degani emas!
                    Sizga berilayotgan bilimlarni yaxshi o'zlashtirishga harakat qiling. Dasturlashni o'rganishga
                    bir kunda kamida ikki soat vaqtingizni ajrating. Sizga bilim berayotgan ustozingizni barcha
                    bergan
                    topshiriqlarini o'z vaqtida bajarsangiz, albatta siz kelajakda yaxshi dasturchi bo'lasiz.
                    Erinchoqlik yoki o'qishga nisbatan mas'uliyatsizlik qilsangiz, ustozingiz qanchalik yaxshi bilim
                    bermasin, siz kelajakda dasturchi bo'lolmasligingiz mumkin.
                </p>
            </div>
        </div>
        <div class="senter pt-0">
            <div class="divp7">
                Kurs haqida savollaringiz qolgan bo’lsa, yoki to’lovni amalga oshirishda muammolar bo’lsa -
                administratorimizga murojaat qilishingiz mumkin. <br/><br/> Administrator bilan quyidagi
                <strong> instagram: </strong>
                <a
                    class="hover-decoration"
                    href="https://www.instagram.com/kadirovdevadmin/"
                    target="_blank"
                >
                    @kadirovDevAdmin
                </a>,
                <strong> telefon raqam: </strong>
                <a
                    class="hover-decoration"
                    :href="formatPhoneNumber('+998901305020')"
                    target="_blank"
                >
                    +998 90 130-50-20
                </a>,
                <strong> telegram: </strong>
                <a
                    class="hover-decoration"
                    href="https://t.me/kadirovDevAdmin"
                    target="_blank"
                >
                    @kadirovDevAdmin
                </a> orqali bog'lanish mumkin.
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import ImgTag from "@/components/html/ImgTag";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import Tech from "@/components/html/Tech";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
    name: "CoursePage",
    components: {ButtonComponent, ImgTag, Quote, Tech},
    computed: {
        ...mapGetters([
            'getToken',
            'getBoughtCourse',
            'getOneCourse',
            'isAdmin',
            'getFoundPromo',
            'getCourseById',
            'getTeachersList',
            'getUserInfo',
            'getModules',
            'getFindCourses',
            'getModule',
            'getMineBoughtCoursesList',
            'getStudentCourses',
            'getUserCourses'
        ]),
        isAuthenticated() {
            return this.getToken
        },
    },
    data() {
        return {
            paymeUrl: process.env.VUE_APP_PAYME_URL,
            paymeLogin: process.env.VUE_APP_PAYME_LOGIN,
            boughtCourse: {
                courses: []
            },
            showPromoCodeInput: false,
            myBoughtCourses: [],
            totalPrice: 0,
            diffTotalPrice: 0,
            hasDiscount: false,
            payedCourses: [],
            payme: {
                amount: 0,
                price: 0,
                transactionId: 0,
                description: 'Kompyuter texnologiyalari sohasida maslahat xizmatlari',
                detail: ''
            },
            detailDto: {
                items: [
                    {
                        count: 1,
                        title: 'Kompyuter texnologiyalari sohasida maslahat xizmatlari',
                        price: null,
                        code: '10305013001000000',
                        package_code: '1545643',
                        vat_percent: 0
                    }
                ],
            },
            interval: '',
            promo: '',
            validPromo: '',
            show: true,
            course: {
                name: '',
                moduleId: null,
                startMonth: '',
                price: '',
                availableCount: '',
                moduleIndex: 0,
                teacher: {
                    givenName: null,
                    familyName: null
                }
            },
            hasActiveCourses: false,
            loading: true,
        }
    },
    methods: {
        ...mapActions([
            'fetchBoughtCourse',
            'searchCourse',
            "searchOneCourse",
            'addStudentToCourse',
            'findPromoCode',
            'fetchTeachers',
            'fetchUser',
            'fetchModulesJunior',
            'findOneCourseJunior',
            'fetchModule',
            'fetchMineBoughtCoursesNew',
            'fetchStudentCourses',
            'fetchUserCourses'
        ]),
        showFreePlace(countOfStudents, members) {
            if (members <= 0) {
                return countOfStudents
            }

            let freePlace = countOfStudents - members;

            if (freePlace > 10) {
                const secondDigit = freePlace % 10;
                if (secondDigit > 5) {
                    return secondDigit - 5;
                }

                return secondDigit;
            }

            return freePlace;
        },
        showPromoInput() {
            this.showPromoCodeInput = true
        },
        formatPhoneNumber(number) {
            return `tel:${number}`
        },
        isDisabledCheck(course) {
            for (let studentCourse of this.getUserCourses) {
                if (course.id === studentCourse.course.id) {
                    return true;
                }
            }

            return false;
        },
        totalSum() {
            if (this.getFoundPromo.amount < this.boughtCourse.courses.length) {
                return this.getFoundPromo.discount * this.getFoundPromo.amount / 100
            }

            return this.getFoundPromo.discount * this.getFoundPromo.promos.length / 100
        },
        checkAction(e) {
            let id = e.target._value

            if (e.target.checked) {
                this.getModule.courses.map(course => {
                    if (!this.boughtCourse.courses.find(c => c === course.id) && course.id < id && course.isActive) {
                        this.boughtCourse.courses.push(course.id)
                    }
                })
                this.myBoughtCourses = this.boughtCourse.courses.length
            } else {
                this.myBoughtCourses = this.getMineBoughtCoursesList.length
                this.getModule.courses.map(course => {
                    if (course.id > id) {
                        for (let i = 0; i < this.boughtCourse.courses.length; i++) {
                            if (this.boughtCourse.courses[i] === course.id) {
                                this.boughtCourse.courses.splice(i, 1)
                            }
                        }
                    }
                })
                this.myBoughtCourses = this.boughtCourse.courses.length
            }

            this.calcTotalSum()
        },
        calcTotalSum() {
            this.totalPrice = 0
            this.diffTotalPrice = 0

            for (let courseId of this.boughtCourse.courses) {
                let course = this.getModule.courses.find(el => el.id === courseId)

                this.totalPrice += course.price

                if (this.getFoundPromo !== null) {
                    for (let promo of this.getFoundPromo.promos) {
                        if (course.type.name === promo.name && (!course.boughtCourses.length || !course.boughtCourses.some(el => el.isPayed === true))) {
                            if (this.getFoundPromo.isPercent) {
                                this.totalPrice -= (course.price / 100) * (this.getFoundPromo.discount / 100)
                            } else {
                                this.totalPrice -= this.getFoundPromo.discount
                            }
                        }
                    }
                }
            }

            this.getUserCourses.forEach(studentCourse => {
                if (studentCourse.course.module.id === this.course.moduleId) {
                    this.diffTotalPrice += studentCourse.course.price
                }
            })

            this.totalPrice -= this.diffTotalPrice
            this.calcDiscount()
        },
        calcDiscount() {
            this.hasDiscount = this.boughtCourse.courses.length === this.getModule.courses.length;

            if (this.getToken) {
                this.getModule.courses.forEach(course => {
                    if (!course.isActive || !course.countOfStudents > 0) {
                        this.hasDiscount = false
                    }
                })
                this.getUserCourses.forEach(myBoughtCourse => {
                    if (myBoughtCourse.course.module.id === this.course.moduleId) {
                        this.hasDiscount = false
                    }
                })
            }
        },
        toDown() {
            let purchaseForCourse = document.querySelector('.form-register');
            let fixedBtn = document.getElementById('goToPurchase');

            const termsObserverCallback = (purchaseForCourse) => {
                if (purchaseForCourse[0].isIntersecting) {
                    fixedBtn.style.display = 'none';
                } else {
                    fixedBtn.style.display = 'block';
                }
            };

            const termsObserverOptions = {}
            const termsObserver = new IntersectionObserver(termsObserverCallback, termsObserverOptions)

            termsObserver.observe(purchaseForCourse)
        },
        bought() {
            this.getModule.courses.map((el) => {
                this.getUserCourses.forEach(course => {
                    if (course.course.type.id === el.type.id) {
                        this.boughtCourse.courses.shift()
                    }
                })
            })
            this.searchOneCourse(this.boughtCourse.courses)
                .then(() => {
                    if (this.getOneCourse.members >= this.getOneCourse.countOfStudents) {
                        this.$refs['modal-success'].show()

                        return
                    }

                    if (this.boughtCourse.courses.length === this.getModule.courses.length && this.validPromo) {
                        this.hasDiscount = false
                    }

                    this.fetchBoughtCourse({
                        courses: this.boughtCourse.courses.sort((a, b) => a - b),
                        promo: this.validPromo,
                        hasDiscount: this.hasDiscount
                    })
                        .then(() => {
                            this.payme.amount = 0
                            this.getBoughtCourse.forEach((boughtCourse) => {
                                this.payme.amount += boughtCourse.price
                            })
                            this.payme.transactionId = this.getBoughtCourse[0].paymeTransaction
                            this.payme.description = 'Dasturchi maslahati uchun to\'lov'
                            this.detailDto.items[0].price = this.payme.amount
                            this.payme.detail = btoa(JSON.stringify(this.detailDto))
                        })
                        .then(() => {
                            this.$refs.paymeButton.click()
                        })
                        .catch((reason) => {
                            switch (reason) {
                                case 'You already payed for this course':
                                    this.$refs['you-payed'].show()
                                    break

                                case 'This promo is expired':
                                case 'Promo is not found':
                                    this.$refs['modal-promo'].show()
                                    break

                                case 'You should buy dependency course':
                                    this.$refs['can-not-bought'].show()
                                    break
                            }
                        })
                })
        },
        findPromo() {
            this.findPromoCode({
                promo: this.promo,
                courseIds: this.boughtCourse.courses
            })
                .then(() => {
                    if (this.getFoundPromo === null) {
                        this.$refs['modal-promo'].show()
                    }
                    this.validPromo = this.promo
                    this.calcTotalSum()
                })
                .catch((reason) => {
                    if (reason === 'Promo is not found' || reason === 'This promo is expired') {
                        this.$refs['modal-promo'].show()
                    }
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$refs['modal-promo'].hide()
            this.$refs['you-payed'].hide()
            this.$refs['can-not-bought'].hide()
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        fetchAndShowJuniorModules() {
            this.fetchModulesJunior()
                .then(() => {
                    for (let i = 0; i < this.getModules.length; i++) {
                        if (this.getModules[i].courses[0].countOfStudents > 0 && this.getModules[i].courses[0].isActive) {
                            this.course.moduleId = this.getModules[i].id
                            break
                        }
                    }

                    if (this.course.moduleId !== null) {
                        this.hasActiveCourses = true
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fetchAndShowJuniorCourses() {
            for (let course of this.getModule.courses) {
                if (this.getToken && this.getUserCourses.length) {
                    this.getUserCourses.forEach(studentCourse => {
                        if (course.isActive || (!course.isActive && course.type.id === studentCourse.course.type.id)) {
                            if (this.boughtCourse.courses.find(el => el === course.id) === undefined) {
                                this.boughtCourse.courses.push(course.id)
                            }
                        }
                    })
                } else {
                    if (course.isActive) {
                        if (this.boughtCourse.courses.find(el => el === course.id) === undefined) {
                            this.boughtCourse.courses.push(course.id)
                        }
                    }
                }

                if (course.teacher !== null) {
                    this.course.teacher.givenName = course.teacher.givenName
                    this.course.teacher.familyName = course.teacher.familyName
                }
            }
        },
    },
    created() {
        this.findOneCourseJunior(this.course.moduleId)
        this.interval = setInterval(() => {
            this.findOneCourseJunior(this.course.moduleId)
                .then(() => {
                    this.getFindCourses
                })
        }, 60000 * 5)
    },
    destroyed() {
        clearInterval(this.interval)
    },
    watch: {
        'course.moduleId'() {
            this.boughtCourse.courses = []
            this.fetchModule(this.course.moduleId)
                .then(() => {
                    this.hasActiveCourses = true
                    this.fetchAndShowJuniorCourses()
                    this.myBoughtCourses = this.boughtCourse.courses.length
                    this.calcTotalSum()
                })
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.toDown()
        this.show = true
        this.fetchUser()
            .then(() => {
                this.fetchUserCourses(this.getUserInfo.id)
                    .then(() => {
                        this.fetchAndShowJuniorModules()
                    })
            })
            .catch(() => {
                this.fetchAndShowJuniorModules()
            })
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

.course-info {
    padding: 5% 8%;
}

* {
    padding: 0;
    margin: 0;
}

.promo-text {
    font-size: 20px;
}

.course-check {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.bg-color {
    border-radius: 10px;
    padding: 20px;
    background-color: #e5ffe5;
}

.course-check:hover {
    color: #80007F;
}

.border-module {
    border: 1px solid #80007F;
    padding: 10px;
    border-radius: 10px;
}

.border-cover {
    padding: 5px 0;
    border-bottom: 1px solid #80007F;
}

input[type="checkbox"] {
    display: block;
    accent-color: #80007F;
    width: 22px;
    height: 22px;
    line-height: 22px;
    content: "";
}

.course-color {
    font-weight: bold;
    color: #80007F;
}

.teachers {
    font-weight: bolder;
    font-family: 'Comfortaa', cursive;
}

.junior-theme {
    font-family: 'Comfortaa', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom: 18px;
}

.junior-text {
    font-family: 'Comfortaa', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #EAEAEA;
    margin-bottom: 42px;
}

.fadeInLeft {
    animation-name: fadeInLeft;
    animation-duration: 1s;
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 1s;
}

.fadeIn {
    animation: fadeIn;
    animation-duration: 1s;
}

.row {
    margin: auto;
}

.course-title-box {
    color: #fff;
    background-color: #80007F;
    min-height: 164px;
    width: 100%;
}

.course-title-box:hover {
    background-color: #990098;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

.discount {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: red;
    text-decoration: underline;
}

.course-title {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    margin: auto;
}

.information {
    margin: auto;
}

.heading {
    color: #FFFFFF;
}

.course-subtitle {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin: auto;
}

.align {
    text-align: justify;
}

.font {
    font-family: 'Comfortaa', cursive;
}

.form-register {
    padding-top: 0;
}

.text-center {
    text-decoration: black;
    font-size: 2em;
    font-weight: bold;
}

.more {
    /*text-decoration: none;*/
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    text-decoration-line: underline;
    padding-top: 20px;
    float: right;
    color: #FFFFFF;
}

.Tech a {
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    text-decoration-line: underline;
    padding-top: 20px;
    float: right;
}

.advice {
    padding: 30px 20% 30px 20%;
}

.senter {
    padding: 110px 20% 110px 20%;
}

.sent {
    padding: 25px 20% 110px 20%;
}

.senter h5 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    padding: 85px 10% 15px 12.5%;
}

.senter h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 30px 0;
}

.sent h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    margin: 30px 0;
}

.divp6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: justify;
}

.divp7 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: justify;
}

.divp6 li {
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 15px;
}

.Tech {
    font-family: Comfortaa, cursive;
    font-style: normal;
    font-weight: normal;
    background: #80007F;
    width: 100%;
    padding: 30px 0 70px;
    height: auto;
}

.courseAddInfo {
    border: solid #1B6EC1 2px;
    max-width: 200px;
    min-width: 160px;
    min-height: 110px;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
    padding-left: 10px;
    outline: none !important;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.select .btn-primary {
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    border-radius: 5px;
    background-color: #80007F !important;
    border: none;
    font-family: Comfortaa, cursive;
    font-size: 16px;
    width: 100%;
    height: 40px;
    margin: 0 10px 0 0;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098 !important;
}

.btn-secondary {
    border-radius: 5px;
    border: none;
    background-color: #027610;
    font-family: Comfortaa, cursive;
    font-size: 16px;
    width: 100%;
    height: 40px;
}

.btn-secondary:hover {
    background-color: #03ab18;
}

.text-bold {
    font-weight: 900;
    font-size: 18px;
}

.login {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
}

.course-reg-button {
    z-index: 1;
}
</style>
